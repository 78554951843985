import {
  CreatePostDto,
  PostDto,
  UpdatePostDto,
} from '@portals/shared/admin/PostDto';

import { request } from './request';

export async function createPost(post: CreatePostDto): Promise<PostDto> {
  const response = await request<PostDto>({
    url: 'posts',
    method: 'POST',
    data: post,
  });
  return response.data;
}

export async function updatePost(
  post: UpdatePostDto,
  id: string,
): Promise<PostDto> {
  const response = await request<PostDto>({
    url: `posts/${id}`,
    method: 'PATCH',
    data: post,
  });
  return response.data;
}

export async function deletePost(id: string): Promise<void> {
  await request<void>({
    url: `posts/${id}`,
    method: 'DELETE',
  });
}
export async function fetchPost(id: string): Promise<PostDto> {
  const response = await request<PostDto>({
    url: `posts/${id}`,
    method: 'GET',
  });
  return response.data;
}

export async function listPosts(): Promise<PostDto[]> {
  const response = await request<PostDto[]>({
    url: 'posts',
    method: 'GET',
  });
  return response.data;
}

export async function sendPost(id: string): Promise<void> {
  await request<PostDto>({
    url: `posts/${id}/send`,
    method: 'POST',
  });
}
