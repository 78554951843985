
















import Vue from 'vue';

import DnbIcon from '@/components/DnbIcon.vue';

enum Style {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export default Vue.extend({
  name: 'dnb-warning',
  components: {
    DnbIcon,
  },
  props: {
    messageStyle: {
      type: String,
      default: Style.Warning,
    },
  },
  computed: {
    getIcon(): string {
      switch (this.messageStyle) {
        case Style.Info:
          return 'InfoWithoutCircle';
        case Style.Warning:
          return 'Warning';
        default:
          return 'Warning';
      }
    },
    getCircleColor(): string {
      switch (this.messageStyle) {
        case Style.Info:
          return this.$style.infoCircle;
        case Style.Warning:
          return this.$style.warningCircle;
        case Style.Error:
          return this.$style.errorCircle;
        default:
          return this.$style.warningCircle;
      }
    },
    getContainerColor(): string {
      switch (this.messageStyle) {
        case Style.Info:
          return this.$style.infoContainer;
        case Style.Warning:
          return this.$style.warningContainer;
        case Style.Error:
          return this.$style.errorContainer;
        default:
          return this.$style.warningContainer;
      }
    },
  },
});
