




import Markdown from 'markdown-it';
import Vue from 'vue';

export interface Heading {
  id: string;
  text: string;
  content: string;
}
const markdown = new Markdown();

const defaultRule: typeof markdown['renderer']['rules'][string] = function (
  tokens,
  idx,
  options,
  environment,
  self,
) {
  return self.renderToken(tokens, idx, options);
};

const defaultLinkOpen = markdown.renderer.rules.link_open ?? defaultRule;
markdown.renderer.rules.link_open = function (
  tokens,
  idx,
  options,
  environment,
  self,
) {
  tokens[idx].attrSet('target', '_blank');
  return defaultLinkOpen(tokens, idx, options, environment, self);
};

const defaultFence = markdown.renderer.rules.fence ?? defaultRule;
markdown.renderer.rules.fence = (tokens, idx, options, environment, self) => {
  return defaultFence(tokens, idx, options, environment, self);
};

export default Vue.extend({
  name: 'markdown',
  props: {
    source: {
      type: String,
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    headerPrefix: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      output: '',
    };
  },

  watch: {
    source(value: string, previous: string) {
      if (value !== previous) {
        this.output = this.renderMarkdown(value);
      }
    },
  },

  created() {
    this.output = this.renderMarkdown(this.source);
  },

  methods: {
    renderMarkdown(source: string) {
      const { headerPrefix } = this;
      return this.inline
        ? markdown.renderInline(source)
        : markdown.render(source, { headerPrefix });
    },
  },
});
