






























import Vue from 'vue';

import AdminPopUp from '@/components/AdminPopUp.vue';
import DnbButton from '@/components/DnbButton.vue';
export default Vue.extend({
  name: 'help-text',
  components: { DnbButton, AdminPopUp },
  props: {
    title: {
      required: true,
      type: String,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data(): { clickedOutside: boolean } {
    return {
      clickedOutside: !this.isOpen,
    };
  },
  methods: {
    closeModal() {
      this.clickedOutside = !this.clickedOutside;
      if (this.clickedOutside) {
        this.close();
      }
    },
  },
});
