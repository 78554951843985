





















import Vue from 'vue';

import DnbIcon from '@/components/DnbIcon.vue';

import { Breadcrumb } from '../models/Breadcrumb';

export default Vue.extend({
  name: 'breadcrumbs',
  components: { DnbIcon },
  props: {
    crumbs: {
      type: Array as () => Breadcrumb[],
      default: Array as () => Breadcrumb[],
    },
  },
});
