















































































































































import { CreatePostDto } from '@portals/shared/admin/PostDto';
import { format } from 'date-fns';
import Vue from 'vue';

import AdminPopUp from '@/components/AdminPopUp.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DnbButton from '@/components/DnbButton.vue';
import DnbCheckbox from '@/components/DnbCheckbox.vue';
import DnbInput from '@/components/DnbInput.vue';
import DnbTabs, { Tab } from '@/components/DnbTabs.vue';
import DnbTextarea from '@/components/DnbTextarea.vue';
import DnbWarning from '@/components/DnbWarning.vue';
import Loader from '@/components/Loader.vue';
import Markdown from '@/components/Markdown.vue';
import { Breadcrumb } from '@/models/Breadcrumb';
import {
  createPost,
  deletePost,
  fetchPost,
  sendPost,
  updatePost,
} from '@/service/postService';
import { fetchTags } from '@/service/tagService';
interface Data {
  post: CreatePostDto;
  tagFilter: Record<string, boolean>;
  saving: boolean;
  error: Error | null;
  tags: string[];
  publishPopUp: boolean;
  counter: number;
  sendEmail: boolean;
  emailed: boolean;
  errors: Record<string, unknown>;
  loading: boolean;
  deletePopUp: boolean;
  formattedDate: string;
}

export default Vue.extend({
  name: 'post',
  components: {
    Loader,
    DnbTextarea,
    DnbCheckbox,
    DnbButton,
    DnbInput,
    DnbTabs,
    Markdown,
    AdminPopUp,
    DnbWarning,
    Breadcrumbs,
  },
  data(): Data {
    return {
      post: {
        content: '',
        tags: [],
        title: '',
        lead: '',
        publishedAt: null,
      },
      tags: ['Currencies', 'PSD2'],
      tagFilter: {},
      saving: false,
      error: null,
      errors: {},
      publishPopUp: false,
      counter: 0,
      sendEmail: false,
      emailed: false,
      loading: true,
      deletePopUp: false,
      formattedDate: format(Date.now(), 'YYYY-MM-DD'),
    };
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      return [
        {
          name: 'News',
          path: '/news',
        },
        {
          name: this.post.title || 'Create Post',
        },
      ];
    },
    selectedTags(): string[] {
      return this.tags.filter((tag) => this.tagFilter[tag]);
    },
    tabs(): Tab[] {
      return [
        { id: 'compose', title: 'Compose' },
        { id: 'preview', title: 'Preview' },
      ];
    },
    postId(): string | undefined {
      return this.$route.params.id;
    },
  },
  async mounted() {
    try {
      await this.fetchPost();
      this.tags = (await fetchTags()).map((tag) => tag.title);
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async fetchPost() {
      if (this.postId) {
        const post = await fetchPost(this.postId);
        this.post = {
          content: post.content,
          tags: post.tags,
          title: post.title,
          lead: post.lead,
          publishedAt: post.publishedAt,
        };
        if (post.publishedAt) {
          this.formattedDate = format(post.publishedAt, 'YYYY-MM-DD');
        }
        this.tagFilter = post.tags.reduce(
          (accumulator: Record<string, boolean>, tag) => {
            accumulator[tag] = true;
            return accumulator;
          },
          {},
        );
        this.emailed = !!post.emailsSentAt;
      }
    },
    async deletePost() {
      if (this.postId) {
        try {
          await deletePost(this.postId);
          this.$router.push('/news');
        } catch (error) {
          this.error = error;
        }
      }
    },
    closeModal() {
      this.counter++;
      if (this.counter > 1) {
        this.publishPopUp = false;
        this.deletePopUp = false;
        this.counter = 0;
      }
    },
    async save(publish: boolean) {
      this.saving = true;
      const postData = {
        ...this.post,
        tags: this.selectedTags,
      };
      if (publish) {
        postData.publishedAt = Date.parse(this.formattedDate);
      }
      try {
        if (this.postId) {
          await updatePost(postData, this.postId);
          if (this.sendEmail) {
            await sendPost(this.postId);
          }
        } else {
          const { id } = await createPost(postData);
          if (this.sendEmail) {
            await sendPost(id);
          }
        }
        this.$router.push('/news');
      } catch (error) {
        if (error.response?.data?.code === 'ValidationError') {
          this.errors = error.response.data.errors;
        } else {
          this.error = error.response?.data?.message ?? error.message;
        }
        this.closeModal();
      } finally {
        this.saving = false;
      }
    },
  },
});
