


















import Vue from 'vue';

export interface Tab {
  id: string;
  title: string;
}

interface Data {
  selected?: string;
}

export default Vue.extend({
  props: {
    tabs: {
      type: Array as () => Tab[],
      required: true,
    },
  },
  data(): Data {
    const [first] = this.tabs;
    return {
      selected: first?.id,
    };
  },
  methods: {
    select(tab: Tab) {
      this.selected = tab.id;
    },
  },
});
